// CookieConsentDialog.js
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useCookie } from "../../context/CookieProvider"; // Adjust the import path if necessary

const CookieConsentDialog = ({ open, onClose, onAccept, onDecline }) => {
  const { setCookie } = useCookie();

  const handleAccept = () => {
    setCookie("google_analytics", "accepted", 365, "/");
    onAccept(); // Call the passed in onAccept callback
    onClose();
  };

  const handleDecline = () => {
    setCookie("google_analytics", "declined", 365, "/");
    onDecline(); // Call the passed in onDecline callback
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Cookie Consent</DialogTitle>
      <DialogContent>
        <DialogContentText>
          We use cookies to enhance your experience. By continuing to visit this
          site you agree to our use of cookies.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept} color='primary' autoFocus>
          Accept
        </Button>
        <Button onClick={handleDecline} color='primary'>
          Decline
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieConsentDialog;
