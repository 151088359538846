import {
  Card,
  CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import MonetaryText from "../MonetaryText";

import { useSfs } from "../../context/SfsProvider";

/**
 * SFS summary component
 * */
const SfsSummary = ({ id, title }) => {
  const { categories, sfsTotals } = useSfs();

  return (
    <Card id={id} sx={{ borderRadius: "0px" }}>
      <CardHeader title={title} />
      <CardContent>
        <TableContainer sx={{ borderRadius: "0px", paddingBottom: "200px" }}>
          <Table aria-label='summary table'>
            <TableBody>
              {categories.map((category, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell sx={{ fontSize: 16, fontWeight: "bold" }}>{category.name}</TableCell>
                    <TableCell
                      id={`${category.name
                        .toLowerCase()
                        .replace("& ", "")
                        .replace(" ", "-")}-amount`}
                      sx={{ fontSize: 16, fontWeight: "bold", textAlign: "right" }}
                    >
                      <MonetaryText
                        value={
                          sfsTotals.categories.find(
                            (item) => item.name === category.name
                          ).value
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default SfsSummary;
