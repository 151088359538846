// ScriptLoader.js
import React, { useEffect } from "react";
import { useCookie } from "../../context/CookieProvider";

const ScriptLoader = () => {
  const { getCookie } = useCookie();

  useEffect(() => {
    const consent = getCookie("google_analytics");
    if (consent === "accepted") {
      // Load Google Analytics
      const gaScript = document.createElement("script");
      gaScript.src = "https://www.googletagmanager.com/gtag/js?id=G-GC0GN8WQPK";
      gaScript.async = true;
      document.head.appendChild(gaScript);

      gaScript.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("consent", "update", {
          analytics_storage: "granted",
        });
        gtag("config", "G-GC0GN8WQPK");
      };

      // Load Google Tag Manager
      const gtmScript = document.createElement("script");
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=GTM-MKVPQH6N`;
      gtmScript.async = true;
      document.head.appendChild(gtmScript);
    }
  }, [getCookie]);

  return null;
};

export default ScriptLoader;
