import { useState } from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";

import ArrangementSetup from "./ArrangementSetup";
import ArrangementMethod from "./ArrangementMethod";
import ArrangementConfirmation from "./ArrangementConfirmation";

import { useArrangement } from "../../context/ArrangementProvider";
import { useSfs } from "../../context/SfsProvider";

const createPlanSteps = [
  { id: "create-plan", title: "Create plan" },
  { id: "payment-details", title: "Payment details" },
  { id: "plan-confirmation", title: "Plan confirmation" },
];

const changePlanSteps = [
  { id: "amend-plan", title: "Amend plan" },
  { id: "plan-summary", title: "Plan summary" },
  { id: "plan-confirmation", title: "Plan confirmation" },
];

/**
 * Arrangement Stepper
 * @returns 
 */
const ArrangementStepper = () => {

  const { planMode, setPlanMode, savePlanData, plan } = useArrangement();
  const { disposableIncome } = useSfs();

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep === 1) {
      savePlanData(plan);
      setPlanMode("amend");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Show steps depending on arrangement mode
  const steps = planMode === "create" ? createPlanSteps : changePlanSteps;

  return disposableIncome && (
    <Box sx={{ width: "100%" }}>
      {/* Step Header */}
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          mt: 7,
          mb: 5,
          "& .MuiStepConnector-root": {
            top: "21px",
            left: "calc(-50% + 35px)",
            right: "calc(50% + 35px)",
          },
          "& .MuiStepConnector-root-line": {
            right: "35px",
            left: "35px",
            flex: "1 1 auto",
            position: "absolute",
            top: "21px",
          },
        }}
      >
        {steps.map((step) => {
          return (
            <Step id={step.id} key={step.title}>
              <StepLabel
                StepIconProps={{
                  sx: {
                    fontSize: "45px",
                    "&.Mui-active": {
                      color: "primary.dark",
                    },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                }}
                sx={{
                  "& .MuiStepLabel-labelContainer": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  "& .MuiStepLabel-root": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  },
                }}
              >
                {step.title}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {/* Step 1 */}
      {activeStep === 0 && (
        <ArrangementSetup handleNext={handleNext} />
      )}

      {/* Step 2 */}
      {activeStep === 1 && (
        <ArrangementMethod
          handleNext={handleNext}
          handleBack={handleBack}
        />
      )}

      {/* Step 3 */}
      {activeStep === 2 && (
        <ArrangementConfirmation
          activeStep={activeStep}
          steps={steps}
        />
      )}
    </Box>
  );
};

export default ArrangementStepper;
