import {
  Box,
  Stack,
  Typography,
  TextField,
  Card,
  CardContent,
  Checkbox,
} from "@mui/material";
import { PatternFormat } from "react-number-format";

import { useArrangement } from "../../context/ArrangementProvider";

const handleSubmit = (event) => {
  event.preventDefault();
};

function ArrangementDirectDebitForm({ consent, setConsent, update=false, currentSortCode=null, currentAccountNumber=null }) {
  /* The create repayment DD method form component */

  const { plan, setPlan } = useArrangement();

  return (
    <Card
      sx={{
        borderRadius: { xs: 0, md: 0 },
        pb: 2,
      }}
    >
      <Box component='form' onSubmit={handleSubmit} noValidate>
        <Stack
          sx={{
            paddingX: 1,
          }}
          direction={{
            xs: "column",
            md: "row",
          }}
        >
          {/* Direct debit form existing mandate left column */}
          {update && <CardContent
            sx={{
              textAlign: "left",
              width: { xs: "100%", md: "50%" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 2 }}>
              Current details
            </Typography>
            <Typography
              component='label'
              htmlFor='sortCode'
              fontWeight={"bold"}
            >
              Sort code
            </Typography>
            <TextField
              disabled
              margin='dense'
              required
              id='sortCode'
              name='sortCode'
              value={currentSortCode || "Not applicable"}
              sx={{ mb: 1, backgroundColor: "lightgrey" }}
            />

            <Typography
              component='label'
              htmlFor='accountNumber'
              fontWeight={"bold"}
            >
              Account number
            </Typography>
            <TextField
              disabled
              margin='dense'
              required
              id='accountNumber'
              name='accountNumber'
              value={currentAccountNumber || "Not applicable"}
              sx={{ mb: 1, backgroundColor: "lightgrey" }}
            />
          </CardContent>}
          {/* End of direct debit form existing mandate left column */}

          {/* Direct debit form right column */}
          <CardContent
            sx={{
              textAlign: "left",
              width: { xs: "100%", md: "50%" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 2 }}>
              Enter new details
            </Typography>
            <Typography
              component='label'
              htmlFor='sortCode'
              fontWeight={"bold"}
            >
              Sort code
            </Typography>
            <PatternFormat
              id='sortCode'
              customInput={TextField}
              name='sortCode'
              margin='dense'
              required
              format="######"
              value={plan?.sortCode}
              valueIsNumericString={true}
              onChange={(event) => setPlan({ ...plan, sortCode: event.target.value})}
              InputProps={{
                style: {color: "darkgrey", borderRadius: 0, pb: 1, mb: 1 },
              }}
              placeholder='Enter your 6 digit sort code'
            />

            <Typography
              component='label'
              htmlFor='accountNumber'
              fontWeight={"bold"}
            >
              Account number
            </Typography>
            <PatternFormat
              id='accountNumber'
              name='accountNumber'
              customInput={TextField}
              margin='dense'
              required
              format="########"
              value={plan?.accountNumber}
              valueIsNumericString={true}
              onChange={(event) => setPlan({ ...plan, accountNumber: event.target.value })}
              InputProps={{
                style: {color: "darkgrey", borderRadius: 0, pb: 1, mb: 1 },
              }}
              placeholder='Enter your 8 digit account number'
            />
          </CardContent>
          {/* End of direct debit form right column */}
        </Stack>

        {/* Payment method confirmation */}
        <Box
          id='declaration'
          sx={{ display: "flex", flexDirection: "row", pl: 2 }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              id='declaration-checkbox'
              checked={consent}
              onChange={() => {
                setConsent(!consent);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <Box id='declaration-statement' padding={0.5} marginRight={2}>
            <Typography
              component='h2'
              variant='p'
              sx={{ display: "inline-block", mt: 2, fontSize: 16 }}
            >
              I confirm that I am the account holder and have the authority to
              set up Direct Debit payments on this account.
            </Typography>
            <Typography
              component='h2'
              variant='p'
              sx={{ display: "inline-block", mt: 2, fontSize: 16 }}
            >
              For further details, please refer to the Direct Debit Guarantee.
            </Typography>
          </Box>
        </Box>
        {/* End of payment method confirmation */}
      </Box>
    </Card>
  );
}

export default ArrangementDirectDebitForm;