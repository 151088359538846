import { useEffect } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";

import SfsCategoryButton from "./SfsCategoryButton";
import SfsSummary from "./SfsSummary";
import SfsInputRow from "./SfsInputRow";

import { useSfs } from "../../context/SfsProvider";

function SfsExpenditureFlexible({ handleNext, handleBack }) {
  /* The flexible expenditure component */

  const {
    sfsExpenditureFlexible,
    setSfsExpenditureFlexible,
    setSfsCategoryTotal,
    categories,
    setCategories,
    changeCategory,
    selectedCategory,
    setSelectedCategory,
    flexValue
  } = useSfs();

  const setItem = (newItem) => {
    const newExpenditureFlexibleValues = sfsExpenditureFlexible.map((item) =>
      item.category === newItem.category && item.title === newItem.title
        ? newItem
        : item
    );
    setSfsExpenditureFlexible(newExpenditureFlexibleValues);
    setSfsCategoryTotal(newItem, newExpenditureFlexibleValues);
  };

  const titleText = (
    <span>
      {" "}
      <b>Expenditure</b> (Flexible) <b>Summary</b>
    </span>
  );

  useEffect(() => {
    // Set data to flexible expenditure data on load
    if (sfsExpenditureFlexible) {
      // Get unique array of categories in data
      const uniqueCategoryList = sfsExpenditureFlexible
        .map((item) => item.category)
        .filter((value, index, self) => self.indexOf(value) === index);
      // Convert categories to objects with selected flag
      const uniqueCategories = uniqueCategoryList.map((item, idx) => ({
        name: item,
        selected: idx === 0 ? true : false,
      }));
      setCategories(uniqueCategories);
      setSelectedCategory(uniqueCategories[0].name);
    }
  }, []);

  return sfsExpenditureFlexible && categories ? (
    <>
      <Typography fontSize={22} paddingBottom={2}>
        <b>Expenditure </b> (Flexible)
      </Typography>
      <Stack
        sx={{
          mt: 2,
        }}
        spacing={8}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <Box
          id='expenditure-flex-container'
          sx={{ width: { xs: "100%", md: "55%" } }}
        >
          {/* Left column */}
     <Box>
            {/* Sub category Inputs */}
            {sfsExpenditureFlexible?.map((item, idx) =>
              item.category === selectedCategory ? (
                <SfsInputRow
                  key={idx}
                  item={item}
                  setItem={setItem}
                  maxValue={flexValue[item.category]}
                />
              ) : null
            )}
          </Box>
          {/* End of left column */}
        </Box>

        <Box sx={{ width: { xs: "100%", md: "45%" } }}>
          {/* Right column */}

          <SfsSummary id='monthly-expenditure-flex-sum' title={titleText} />

          {/* Call To Action */}
          <Box sx={{ textAlign: "center", pt: 2, pb: 4 }}>
            <Button
              id='back-btn'
              onClick={handleBack}
              variant='outlined'
              sx={{
                m: 2,
                textTransform: "none",
                borderRadius: 0,
                width: { xs: "20em", lg: "15em" },
                color: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                  color: "primary.dark",
                },
              }}
            >
              Back
            </Button>

            <Button
              id='save-continue-btn'
              onClick={handleNext}
              variant='contained'
              sx={{
                m: 2,
                textTransform: "none",
                borderRadius: 0,
                width: { xs: "20em", lg: "15em" },
                backgroundColor: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                  color: "primary.dark",
                },
              }}
            >
              Next
            </Button>
          </Box>

          {/* End of right column */}
        </Box>
      </Stack>
    </>
  ) : null;
}

export default SfsExpenditureFlexible;
