import { useState } from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";

import ChangePaymentMethod from "./ChangePaymentMethod";
import ChangePaymentMethodConfirmation from "./ChangePaymentMethodConfirmation";

const steps = [
  { id: "change-payment-method", title: "Change payment method" },
  { id: "change-payment-method-confirmation", title: "Payment method change confirmed" },
];


/**
 * Change Payment Method Stepper
 * @returns 
 */
const ChangePaymentMethodStepper = ({ account, plan, setPlan, savePlan }) => {

  const [activeStep, setActiveStep] = useState(0);

  const handleComplete = () => {
    savePlan(plan);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Step Header */}
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          mt: 7,
          mb: 5,
          "& .MuiStepConnector-root": {
            top: "21px",
            left: "calc(-50% + 35px)",
            right: "calc(50% + 35px)",
          },
          "& .MuiStepConnector-root-line": {
            right: "35px",
            left: "35px",
            flex: "1 1 auto",
            position: "absolute",
            top: "21px",
          },
        }}
      >
        {steps.map((step) => {
          return (
            <Step id={step.id} key={step.title}>
              <StepLabel
                StepIconProps={{
                  sx: {
                    fontSize: "45px",
                    "&.Mui-active": {
                      color: "primary.dark",
                    },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                }}
                sx={{
                  "& .MuiStepLabel-labelContainer": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  "& .MuiStepLabel-root": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  },
                }}
              >
                {step.title}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {/* Step 1 */}
      {activeStep === 0 && (
        <ChangePaymentMethod
          account={account}
          plan={plan}
          setPlan={setPlan}
          handleComplete={handleComplete}
        />
      )}

      {/* Step 2 */}
      {activeStep === 1 && (
        <ChangePaymentMethodConfirmation />
      )}

    </Box>
  );
};

export default ChangePaymentMethodStepper;
