import { createContext, useContext } from 'react';

import useSWRImmutable, { SWRConfig } from 'swr/immutable';

import { useAuth } from './AuthProvider';
import environment from '../env';

/**
 * CustomAccount context
 * */
const CustomAccountContext = createContext();

/**
 * CustomAccount context hook
 * */
export const useCustomAccount = () => useContext(CustomAccountContext)

/**
 * CustomAccount provider
 * @param {*} param0 
 * @returns 
 */
const CustomAccountProvider = ({ children }) => {

  const { accessToken } = useAuth();

  /**
   * Get CustomAccount SWR hook
   * @returns 
   */
  function useGetCustomAccount() {
    const url = accessToken ? `${environment.CUSTOMER_PORTAL_API}/custom-account` : null;
    const options = {
      method: "GET",
      headers: {
        "x-api-key": environment.CUSTOMER_PORTAL_API_KEY,
       "Authorization": `Bearer ${accessToken}`
      },
    };
    console.info("Get CustomAccount request", {options, url})
    const { data, error, isLoading } = useSWRImmutable([url, options])
    console.info("Get CustomAccount response", { data, error, isLoading })
    return {
      CustomAccount: data,
      error,
      isLoading
    }
  }
  const contextValue = {
    useGetCustomAccount
  }

  return (
    <CustomAccountContext.Provider value={ contextValue }>
      {children}
    </CustomAccountContext.Provider>
  );
}

export default CustomAccountProvider;