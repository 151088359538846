import { createContext, useContext } from 'react';
import environment from '../env';
import useSWRImmutable, { SWRConfig } from 'swr/immutable';
import { useAuth } from './AuthProvider';


const AccountContext = createContext();

export const useAccount = () => useContext(AccountContext)

/**
 * Account provider
 * @param {*} param0 
 * @returns 
 */
const AccountProvider = ({ children }) => {

  const { accessToken } = useAuth();

  const registrationReasons = [
    "View & amend your payment plan 24/7",
    "Access to detailed account information",
    "24/7 support through our chat bot assistant KAI",
    "Easily update & manage personal details"
  ]
  
  /**
   * Get account SWR hook
   * @returns 
   */
  function useGetAccount() {
    const url = accessToken && `${environment.CUSTOMER_PORTAL_API}/account`;
    const options = {
      method: "GET",
      headers: {
        "x-api-key": environment.CUSTOMER_PORTAL_API_KEY,
       "Authorization": `Bearer ${accessToken}`
      },
    };
    console.info("Get account request", {options, url})
    const { data, error, isLoading } = useSWRImmutable([url, options])
    console.info("Get account response", { data, error, isLoading })
    return {
      account: data,
      error,
      isAccountLoading: isLoading,
    }
  }

  const contextValue = {
    registrationReasons,
    useGetAccount,
  }

  return (
    <AccountContext.Provider value={ contextValue }>
      {children}
    </AccountContext.Provider>
  );
}

export default AccountProvider;