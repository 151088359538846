import { Box, Typography } from "@mui/material";

import AmountInput from "./AmountInput";

import { useAccount } from "../../context/AccountProvider";
import { useArrangement } from "../../context/ArrangementProvider";

const ArrangementCalculator = ({ setErrorMessage }) => {
  /* The repayment calculator component */

  /* Input: payment amount
    Output estimated end date of plan
    1. cant exceed disposable income
    2. DI <= 50 = 2.50 INCR.
      DI 50 - 100 = 5 INCR.
      DI 100 - 250 = 10 INCR.
      DI 250+ = 25 INCR.  
  */

  const { plan } = useArrangement();

  const { useGetAccount } = useAccount();
  const { account } = useGetAccount();

  return plan?.endDate && (
    <Box
      sx={{
        textAlign: "center",
        borderRadius: 0,
        backgroundColor: "primary.dark",
        color: "primary.contrastText",
        py: 1,
      }}
    >
      <AmountInput setErrorMessage={setErrorMessage} />
      <Typography margin={2} marginTop={1}>
          <Box fontSize={14} marginLeft={1} pr={1}>
            {
              plan?.totalPayable === account?.currentBalance ?
                <Typography>
                  This repayment plan would clear the overall balance of{" "}
                  <b>£{account?.currentBalance}</b>
                </Typography> :
                <Typography>
                  We have a limit of <b>250 instalments</b> per arrangement.{" "}
                  This current repayment plan will clear{" "}
                  <b>£{plan?.totalPayable.toFixed(2)}</b> off the overall account balance of{" "}
                  <b>£{account?.currentBalance.toFixed(2)}</b>
                </Typography>
            }
          </Box>
        </Typography>
      <Typography sx={{ fontSize: 18, pt: 1 }}>
        Estimated End Date
      </Typography>
      <Typography
        sx={{ fontWeight: "bold", fontSize: 26, pb: 2 }}
      >
        {plan?.endDate.format("DD MMM YYYY")}
      </Typography>

    </Box>
  );
};

export default ArrangementCalculator;
