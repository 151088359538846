import { useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  Divider,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import styled from "@emotion/styled";

import useSWRMutation from "swr/mutation";
import Footer from "./Footer";
import { useClient } from "../../context/ClientProvider";
import { useNav } from "../../context/NavProvider";
import { useUtils } from "../../context/UtilsProvider";
import { useAuth } from "../../context/AuthProvider";
import { useSfs } from "../../context/SfsProvider";
import { useArrangement } from "../../context/ArrangementProvider";
//import useWebchatScript from "../../hooks/useWebchatScript";

const AppBarStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    backgroundColor: theme.palette.primary.dark,
  },
  [theme.breakpoints.up("md")]: {
    backgroundColor: "#FFF",
  },
}));

function ResponsiveAppBar() {
  const { navigation, settings, showFooter } = useNav();
  const { accessToken, endSession, postSignOut } = useAuth();
  const {
    sfsMode,
    disposableIncome,
    sfsCreatedDate,
    monthsSinceReview,
    setSfsLoaded,
  } = useSfs();
  const { planMode, plan, setLastSavedPlan, setPlanLoaded } = useArrangement();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { createAlert, alertSeverityTypes } = useUtils();
  const navigate = useNavigate();
  const { trigger, isMutating } = useSWRMutation("/auth/signout", postSignOut);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignOut = async (event) => {
    event.preventDefault();
    try {
      const response = await trigger("");
      console.info("post sign out response: ", response);
      setSfsLoaded(false);
      setPlanLoaded(false);
      setLastSavedPlan(null);
      endSession();
      createAlert("User sign out successful", alertSeverityTypes.success);
      navigate("/auth");
    } catch (error) {
      const message = "Oops! Something went wrong, please try again.";
      console.error("post sign out error", error);
      createAlert(message, alertSeverityTypes.error);
    }
  };

  const { useGetConfig } = useClient();
  const { clientConfig } = useGetConfig();
  // Show webchat demo
  //useWebchatScript();

  return (
    <>
      <AppBar position='static'>
        <AppBarStyle>
          <Container maxWidth='xl'>
            <Toolbar disableGutters>
              <Box
                sx={{
                  width: 130,
                  maxHeight: { xs: 80, md: 100 },
                  maxWidth: { xs: 130, md: 150 },
                  filter: {
                    md: "none",
                    xs: "invert(1)",
                  },
                  py: 2,
                }}
              >
                <Link to='/'>
                  <img src={clientConfig?.layout.header.logo} alt='Logo' />
                </Link>
              </Box>

              {/* Mobile Nav Links */}
              {accessToken && (
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "flex", md: "none" },
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    size='large'
                    aria-label='account of current user'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    onClick={handleOpenNavMenu}
                    color='inherit'
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id='menu-appbar'
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    {navigation &&
                      navigation.map((item, idx) => (
                        <MenuItem key={idx} onClick={handleCloseNavMenu}>
                          <Typography textAlign='center'>
                            <Link
                              id={item.id}
                              to={item.href}
                              style={{
                                textDecoration: "none",
                                color: "#000",
                              }}
                            >
                              {item.name}
                            </Link>
                          </Typography>
                        </MenuItem>
                      ))}

                    <Divider />
                    <MenuItem key='logout' onClick={handleSignOut}>
                      <Typography textAlign='center'>
                        <Link
                          id='logout'
                          to='/'
                          style={{
                            textDecoration: "none",
                            color: "#000",
                          }}
                        >
                          Logout
                        </Link>
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              )}
              {/* End of Mobile Nav Links */}

              {/* Desktop Nav Links */}
              {accessToken && (
                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                  {navigation &&
                    navigation.map(
                      (item, idx) =>
                        !item.hide && (
                          <Button
                            key={idx}
                            onClick={handleCloseNavMenu}
                            sx={{
                              my: 2,
                              color: "white",
                              display: "block",
                              ml: 5,
                              textTransform: "capitalize",
                              fontWeight: "bold",
                              fontSize: { xs: 14, md: 16 },
                            }}
                          >
                            <Link
                              id={item.id}
                              to={item.href}
                              style={{
                                textDecoration: "none",
                                color: "black",
                                verticalAlign: "text-top",
                              }}
                            >
                              {item.name}
                            </Link>
                          </Button>
                        )
                    )}
                </Box>
              )}
              {/* End of Desktop Nav Links */}

              {/* Desktop Logout Button */}
              {accessToken && (
                <Box
                  sx={{
                    flexGrow: 0,
                    display: { xs: "none", md: "flex", position: "relative" },
                  }}
                >
                  <Button
                    id='logout'
                    onClick={handleSignOut}
                    variant='contained'
                    sx={{
                      textTransform: "none",
                      width: { md: "7em" },
                      backgroundColor: "primary.dark",
                      borderRadius: "0px",
                      color: "primary.contrastText",
                      "&:hover": {
                        backgroundColor: "primary.light",
                        color: "primary.dark",
                      },
                    }}
                  >
                    Sign out
                  </Button>
                  {isMutating && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              )}
              {/* End of Desktop Logout Button */}
            </Toolbar>
          </Container>
        </AppBarStyle>
      </AppBar>

      {/* Main Content */}
      <Outlet />
      {/* End of Main Content */}

      {showFooter ? <Footer /> : null}
    </>
  );
}
export default ResponsiveAppBar;
