import environment from "../env";
import { createTheme } from "@mui/material";
import { createContext, useContext, useState, useEffect } from "react";

import useSWRImmutable, { SWRConfig } from "swr/immutable";

import { useCookie } from "./CookieProvider";

// Create context object and make available through custom hook
const ClientContext = createContext();
export const useClient = () => useContext(ClientContext);

const ClientProvider = ({ children }) => {
  const { setCookie, getCookie } = useCookie();

  const [registrationStatus, setRegistrationStatus] = useState(null);
  // TODO: Specify local brand images here until CDN solution
  const [clientLogo] = useState("images/small_logo.png");
  const [bannerBackground] = useState("images/banner_background.png");
  const [clientTheme, setClientTheme] = useState(null);

  // Layout data
  const [layout, setLayout] = useState(null);

  // Client contact us data
  const [intro, setIntro] = useState([
    `Need assistance with your account, have questions about our services, or just want to chat?
     Our team at Vanilla Finance is here to help. Whether you prefer to reach out via phone, email,
     or writing to us, we're ready to assist you with any inquiries or concerns you may have.`,
    `Don't hesitate to get in touch, we're committed to providing you with the support you need.
     We look forward to hearing from you!`,
  ]);
  const [openingHours, setOpeningHours] = useState([
    { days: "Monday - Friday", time: "08:00am to 20:00pm" },
    { days: "Saturday", time: "09:00am to 13:30pm" },
    { days: "Public Holidays", time: "Closed" },
  ]);
  const [clientEmail, setClientEmail] = useState("myaccount.uk@intrum.com");
  const [clientTelephoneNumber, setClientTelephonNumber] =
    useState("+44 1737 237 370");
  const [clientAddress, setClientAddress] = useState([
    `Intrum UK Limited`,
    `The Omnibus Building`,
    `Lesbourne Road`,
    `Reigate`,
    `Surrey`,
    `RH2 7JP`,
  ]);

  const supportLinks = [
    {
      name: "Citizens Advice",
      id: "citizens-advice-link",
      imgLink:
        "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/citizens_advice_image/1/citizens_advice_image.png",
      text: "Citizens Advice provides impartial advice on various issues including debt and benefits. With over 80 years of experience, our expert advisers offer the information and guidance you need to make informed decisions and take control of your life.",
      url: "https://www.citizensadvice.org.uk/",
      tel: "08082231133",
    },
    {
      name: "Mind",
      text: "Mind is a leading mental health charity in the UK, offering support and resources for mental health challenges. We focus on raising awareness, providing information, and offering practical advice to promote understanding and reduce stigma.",
      imgLink:
        "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/mind_image/1/mind_image.png",
      id: "mind-link",
      url: "https://www.mind.org.uk/",
      tel: "03001233393",
    },
    {
      name: "Money Advice Scotland",
      text: "Money Advice Scotland is Scotland’s money charity, helping people in debt, supporting money advisers, and influencing policy. Our mission is to drive financial wellbeing for the people of Scotland.",
      id: "money-advice-scotland-link",
      imgLink:
        "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/money_advice_scotland/1/money_advice_scotland.png",
      url: "https://www.moneyadvicescotland.org.uk/",
      tel: "01415720237",
    },
    {
      name: "Advice UK",
      text: "Member centres offer debt advice, some providing specialist advice for minority, ethnic and refugee communities and people with disabilities. For further information.",
      id: "advice-link",
      imgLink:
        "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/advice_image/1/advice_image.png",
      url: "https://www.adviceuk.org.uk/",
    },
    {
      name: "Christians Against Poverty",
      text: "Through a team of hundreds of dedicated staff members based in all four UK nations, and hundreds of incredible churches delivering our services, people in desperate need are discovering life-changing freedom and hope every day. We are a national debt counselling charity with centres throughout the UK.",
      id: "cap-link",
      imgLink:
        "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/cap_image/1/cap_image.png",
      url: "https://www.capuk.org/",
      tel: "01274761999",
    },
    {
      name: "National Debt line",
      text: "National Debtline is a charity that have been helping people in the UK with debt since 1987. They are run by the Money Advice Trust. Working in England, Wales and Scotland, they have helped millions of people deal with their debts. They are here to help you too.",
      id: "national-debt-link",
      imgLink:
        "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/national_debtline_image/1/national_debtline_image.png",
      url: "https://www.nationaldebtline.org/",
      tel: "08088084000",
    },
    {
      name: "PayPlan",
      text: "Payplan aim to reduce the amount you pay towards your debts, giving you and your loved ones the chance to live more comfortably. In just a few steps, you'll be on a clear path to becoming debt-free.",
      id: "pay-plan-link",
      imgLink:
        "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/pay_plan/1/pay_plan.png",
      url: "https://www.payplan.com/",
      tel: "08003161833",
    },
    {
      name: "MoneyHelper",
      text: "MoneyHelper is here to make your money and pension choices clearer. Here to cut through the complexity, explain what you need to do and how you can do it. Here to put you in control with free, impartial guidance that’s backed by government, and to recommend further, trusted support if you need it.",
      id: "money-helper-link",
      imgLink:
        "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/money_helper_image/1/money_helper_image.png",
      url: "https://www.moneyhelper.org.uk/",
    },
    {
      name: "StepChange Debt Charity",
      text: "StepChange Debt Charity offers expert guidance and support to anyone struggling with debt in the UK. With over 25 years of experience, we help people take control of their finances and build a brighter future.",
      id: "stepchange-link",
      imgLink:
        "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/step_change_image/1/step_change_image.png",
      url: "https://www.stepchange.org/",
      tel: "08001381111",
    },
    {
      name: "Contact Us",
      id: "contact-us-link",
      imgLink:
        "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/logo/contact_us/1/contact_us.png",
      text: "Got a question or need assistance? Reach out to us through any communication channel. Don't hesitate to get in touch - we're always happy to hear from you!",
      url: "/contact-us",
      tel: "01737237370",
    },
  ];
  //
  /*const setClient = (registration, theme, layout, cookies=false) => {
    // Set client state
    setRegistrationStatus(registration);
    setClientTheme(createTheme(theme));
    setLayout(layout);

    // Set cookies if required
    if (cookies) {
      setCookie("registration", registration, 1, "/");
      setCookie("theme", JSON.stringify(theme), 1, "/");
      setCookie("layout", JSON.stringify(layout), 1, "/");
    }
  }*/

  // Hook to call API proxy to client manager config
  function useGetConfig() {
    const url = `${environment.CUSTOMER_PORTAL_API}/config/${environment.CLIENT_NAME}`;
    const options = {
      method: "GET",
      headers: { "x-api-key": environment.CUSTOMER_PORTAL_API_KEY },
    };
    console.info("client config request", { options, url });
    const { data, error, isLoading } = useSWRImmutable([url, options]);
    console.info("client config response", { data, error, isLoading });
    return {
      clientConfig: data,
      error,
      isLoading,
    };
  }

  /*const registrationCookie = getCookie("registration");
  const themeCookie = getCookie("theme");
  const layoutCookie = getCookie("layout");
  if (registrationCookie && themeCookie && layoutCookie) {
    setClient(registrationCookie, JSON.parse(themeCookie), JSON.parse(layoutCookie));
  } else {
    setClient(clientConfig?.registration , clientConfig?.theme, clientConfig?.layout, !isLoading);
  }*/

  const contextValue = {
    registrationStatus,
    clientLogo,
    bannerBackground,
    clientTheme,
    intro,
    openingHours,
    clientEmail,
    clientTelephoneNumber,
    clientAddress,
    supportLinks,
    layout,
    useGetConfig,
  };

  return (
    <ClientContext.Provider value={contextValue}>
      {children}
    </ClientContext.Provider>
  );
};

export default ClientProvider;
