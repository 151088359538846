import { useEffect } from "react";
import { Box, Typography, Stack, Button } from "@mui/material";

import SfsCategoryButton from "./SfsCategoryButton";
import SfsSummary from "./SfsSummary";
import SfsInputRow from "./SfsInputRow";

import { useSfs } from "../../context/SfsProvider";

function SfsAdditionalDebt({ handleBack, handleComplete }) {
  /* The additional debt component */

  const {
    sfsAdditionalDebt,
    setSfsAdditionalDebt,
    setSfsCategoryTotal,
    categories,
    setCategories,
    changeCategory,
    selectedCategory,
    setSelectedCategory,
    addDebtValue
  } = useSfs();

  const setItem = (newItem) => {
    // Update item in state with changes
    const newAdditionalDebtValues = sfsAdditionalDebt.map((item) =>
      item.category === newItem.category && item.title === newItem.title
        ? newItem
        : item
    );
    setSfsAdditionalDebt(newAdditionalDebtValues);
    setSfsCategoryTotal(newItem, newAdditionalDebtValues);
  };

  useEffect(() => {
    // Set data to additional debt on load
    if (sfsAdditionalDebt) {
      // Get unique array of categories in data
      const uniqueCategoryList = sfsAdditionalDebt
        .map((item) => item.category)
        .filter((value, index, self) => self.indexOf(value) === index);
      // Convert categories to objects with selected flag
      const uniqueCategories = uniqueCategoryList.map((item, idx) => ({
        name: item,
        selected: idx === 0 ? true : false,
      }));
      setCategories(uniqueCategories);
      setSelectedCategory(uniqueCategories[0].name);
    }
  }, []);

  return sfsAdditionalDebt && categories ? (
    <>
      <Typography fontSize={22} fontWeight={"bold"} paddingBottom={2}>
        Additional Debt
      </Typography>
      <Stack
        sx={{
          mt: 2,
        }}
        spacing={8}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <Box id='add-debt-container' sx={{ width: { xs: "100%", md: "55%" } }}>
          {/* Left column */}

          <Box sx={{ pb: 2 }}>
            {/* Sub category buttons */}
            {categories?.map((category, idx) => {
              return (
                <SfsCategoryButton
                  key={idx}
                  category={category}
                  changeCategory={changeCategory}
                />
              );
            })}
          </Box>

          <Box>
            {/* Sub category Inputs */}
            {sfsAdditionalDebt?.map((item, idx) =>
              item.category === selectedCategory ? (
                <SfsInputRow
                  key={idx}
                  item={item}
                  setItem={setItem}
                  maxValue={addDebtValue[item.category]}
                />
              ) : null
            )}
          </Box>

          {/* End of left column */}
        </Box>

        <Box sx={{ width: { xs: "100%", md: "45%" } }}>
          {/* Right column */}

          <SfsSummary
            id='monthly-debt-sum'
            title='Monthly Additional Debt Summary'
          />

          {/* Call To Action */}
          <Box sx={{ textAlign: "center", pt: 2, pb: 4 }}>
            <Button
              id='back-btn'
              onClick={handleBack}
              variant='outlined'
              sx={{
                m: 2,
                textTransform: "none",
                borderRadius: 0,
                width: { xs: "20em", lg: "15em" },
                color: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                  color: "primary.dark",
                },
              }}
            >
              Back
            </Button>

            <Button
              id='save-continue-btn'
              onClick={handleComplete}
              variant='contained'
              sx={{
                m: 2,
                textTransform: "none",
                borderRadius: 0,
                width: { xs: "20em", lg: "15em" },
                backgroundColor: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                  color: "primary.dark",
                },
              }}
            >
              Save & Continue
            </Button>
          </Box>

          {/* End of right column */}
        </Box>
      </Stack>
    </>
  ) : null;
}

export default SfsAdditionalDebt;
