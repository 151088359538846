import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Typography,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Container,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import ErrorIcon from "@mui/icons-material/Error";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

import ActionButton from "../../account/ActionButton";

import { useAccount } from "../../../context/AccountProvider";
import { useUtils } from "../../../context/UtilsProvider";
import { useSfs } from "../../../context/SfsProvider";
import { useArrangement } from "../../../context/ArrangementProvider";


// Methods and properties for pie chart calculation
const pieChartSize = 1800;

/**
 * Get paid pie value
 * @param {*} totalPaid
 * @param {*} balance
 * @returns
 */
const getPaidPieValue = (totalPaid, balance) => {
  if (!totalPaid || !balance) {
    return 0;
  }

  // Prepare values
  const totalCredit = totalPaid + balance;

  // Calculate paid section of pie chart
  const percentagePaid = Math.round((totalPaid / totalCredit) * 100);
  const paidPieValue = pieChartSize * (percentagePaid / 100);

  return paidPieValue;
};

/**
 * Create data
 * @param {*} id
 * @param {*} title
 * @returns
 */
function createData(id, title, value) {
  return { id, title, value };
}

/**
 * Arrangement widget component
 * @param {*} param0
 * @returns
 */
function ArrangementWidget() {

  const { capitaliseWords } = useUtils();
  const { useGetAccount } = useAccount();
  const { account, isAccountLoading } = useGetAccount();
  const { disposableIncome } = useSfs();
  const { lastSavedPlan, hasArrangement, paymentMethods, paymentFrequencies } =
    useArrangement();

  let repaymentData = [
    createData(
      "repay-amount",
      "Repayment amount: ",
      `£${lastSavedPlan?.instalmentAmount?.toFixed(2)}`
    ),
    createData(
      "repay-date",
      "Repayment date:",
      dayjs(lastSavedPlan?.nextPaymentDate)?.format("DD/MM/YYYY")
    ),
    createData(
      "pay-freq",
      "Payment frequency:",
      lastSavedPlan?.paymentFrequency
        ? capitaliseWords(paymentFrequencies[lastSavedPlan?.paymentFrequency])
        : ""
    ),
    createData(
      "pay-method",
      "Payment method:",
      capitaliseWords(
        lastSavedPlan?.paymentMethod
          ? Object.keys(paymentMethods).find(
              (key) => paymentMethods[key] === lastSavedPlan?.paymentMethod
            )
          : ""
      )
    ),
    createData(
      "pay-remain",
      "Payments remaining:",
      lastSavedPlan?.paymentsLeft ? lastSavedPlan?.paymentsLeft : 0
    ),
  ];

  // Calculate pie chart values
  const paidPieValue = getPaidPieValue(lastSavedPlan?.paidToDate, lastSavedPlan?.totalRemaining);
  const outstandingPieValue = pieChartSize - paidPieValue;

  let repaymentChartData = [
    {
      id: 0,
      label: `Paid amount`,
      value: lastSavedPlan?.paidToDate || 0,
      pieValue: paidPieValue,
      color: "#00A0A6",
    },
    {
      id: 1,
      label: `Outstanding`,
      value: lastSavedPlan?.totalRemaining || 0,
      pieValue: outstandingPieValue,
      color: "#f5f5f5",
    },
  ];

  // Responsive margin logic
  const getMargin = (width) => {
    if (width < 600) {
      return { top: 120, right: 90, left: 90, bottom: 80 };
    } else {
      return { top: 60, right: 50, left: 50, bottom: 50 };
    }
  };

  const [margin, setMargin] = useState(getMargin(window.innerWidth));
  const [tooltipOffset, setTooltipOffset] = useState(-30); // Initial offset value

  useEffect(() => {
    const handleResize = () => {
      const newMargin = getMargin(window.innerWidth);
      setMargin(newMargin);

      // Adjust Tooltip offset dynamically based on window width
      const newOffset = window.innerWidth < 600 ? -30 : -50; // Example adjustment
      setTooltipOffset(newOffset);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Custom legend item
  const CustomLegend = () => {
    return (
      <Box
        sx={{
          textAlign: "center",
          position: "absolute",
          transform: "translateY(-25%)",
          margin: 1,
          height: 75,
        }}
      >
        {repaymentChartData.map((entry, index) => (
          <Box
            key={`item-${index}`}
            sx={{
              display: "flex",
              alignItems: "center",
              margin: 1,
            }}
          >
            <Box
              sx={{
                width: 16,
                height: 16,
                backgroundColor: entry.color,
                marginRight: 1,
                borderRadius: "50%",
              }}
            />
            <Typography sx={{ color: "#f5f5f5", fontWeight: "bold" }}>
              {entry.label}: £{entry.value.toFixed(2)}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return hasArrangement && lastSavedPlan ? (
    <Grid>
      <Card
        id='repayment-plan-container'
        sx={{
          backgroundColor: "primary.dark",
          color: "primary.contrastText",
        }}
      >
        <Typography
          fontWeight={"bold"}
          fontSize={24}
          paddingTop={1}
          marginLeft={2}
        >
          My repayment plan
        </Typography>
        <Typography marginX={2} marginTop={2}>
          <Box fontSize={14} marginLeft={1} pr={1}>
            {
              lastSavedPlan?.totalPayable === account?.currentBalance ?
                <Typography sx={{ fontSize: 14 }}>
                  This repayment plan would clear the overall balance of{" "}
                  <b>£{account?.currentBalance}</b>{". "}
                  The amount of the final instalment will be{" "}
                  <b>£{lastSavedPlan?.finalPaymentAmount?.toFixed(2)}</b>{"."}
                </Typography> :
                <Typography sx={{ fontSize: 14 }}>
                  We have a limit of <b>250 instalments</b> per arrangement.{" "}
                  This current repayment plan will clear{" "}
                  <b>£{lastSavedPlan?.totalPayable.toFixed(2)}</b> off the overall account balance of{" "}
                  <b>£{account?.currentBalance?.toFixed(2)}</b>{"."}
                </Typography>
            }
          </Box>
        </Typography>
        {/* About Repayment Section */}
        <TableContainer sx={{ px: 1, py: 1 }}>
          <Table
            sx={{ minWidth: "100%" }}
            size='small'
            aria-label='repayment plan table'
          >
            <TableBody>
              {repaymentData.map((row) => (
                <TableRow
                  key={row.title}
                  sx={{
                    "td, th": { border: 0 },
                    th: { color: "#FFF" },
                    td: { color: "#FFF" },
                  }}
                >
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    component='th'
                    scope='row'
                  >
                    {row.title}
                  </TableCell>
                  <TableCell id={row.id} align='right'>
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pie Chart Section */}
        <Box
          sx={{
            position: "relative",
            top: "55px",
            width: "100%",
            height: "197px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <ResponsiveContainer width='100%' height={300}>
            <PieChart width={300} height={300} margin={margin}>
              <Pie
                data={repaymentChartData}
                dataKey='pieValue'
                startAngle={180}
                endAngle={0}
                innerRadius={120}
                outerRadius={150}
                nameKey='label'
              >
                {repaymentChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip
                contentStyle={{
                  backgroundColor: "#FFF",
                  border: "none",
                  borderRadius: "5px",
                  padding: "5px",
                }}
                position={{ x: 0, y: 0 }}
                offset={tooltipOffset}
                formatter={(value, name, props) => {
                  const actualValue = props.payload.value;
                  const paidAmount = paidPieValue;
                  return [
                    `Outstanding £${actualValue.toFixed(2)}`,
                    `Paid Amount £${paidAmount.toFixed(2)}`,
                    props.payload.label,
                  ];
                }}
              />
            </PieChart>
          </ResponsiveContainer>
          <CustomLegend />
        </Box>

        {/* Call To Action */}
        <Box sx={{ textAlign: "center", pt: 2, pb: 2 }}>

          <Container sx={{ pb: 4 }}>
            <ActionButton
              id='view-amend-btn'
              buttonColor='primary.contrastText'
              buttonTextColor='primary.dark'
              buttonTitle='View or Amend plan'
              hoverColor='primary.light'
              hoverTextColor='primary.dark'
              target='/repayment'
            />
          </Container>

          {/* <Container>
            <ActionButton
              id='change-payment-btn'
              buttonColor='primary.main'
              buttonTextColor='primary.contrastText'
              buttonTitle='Change payment details'
              hoverColor='primary.light'
              hoverTextColor='primary.dark'
              target='/repayment/change-payment'
            />
          </Container> */}
        </Box>

      </Card>
    </Grid>
  ) : (
    <Grid>
      <Card
        id='repayment-plan-container'
        sx={{
          backgroundColor: "primary.dark",
          color: "primary.contrastText",
          borderRadius: 0,
        }}
      >
        <Typography
          fontWeight={"bold"}
          fontSize={24}
          paddingTop={1}
          marginLeft={2}
        >
          My repayment <ErrorIcon sx={{ color: "#EA4278" }} />
        </Typography>

        <Typography
          fontSize={14}
          paddingTop={1}
          paddingBottom={1}
          marginX={2}
          color='#FFF'
        >
          There's no active repayment plan set up on your account.{" "}
          Want to steadily reduce your balance with an affordable plan?{" "}
          Let's take a look at your options...
        </Typography>

        {/* Call To Action */}
        <Box sx={{ textAlign: "center", pt: 2, pb: 4 }}>
          <ActionButton
            id='create-plan-btn'
            buttonColor='#FFF'
            buttonTextColor='primary.dark'
            buttonTitle='My repayment plan options'
            hoverColor='primary.light'
            hoverTextColor='primary.dark'
            target='/repayment'
          />
        </Box>
      </Card>
    </Grid>
  );
}

export default ArrangementWidget;
