import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { SWRConfig } from "swr";

import AccountProvider from './context/AccountProvider';
import ArrangementProvider from './context/ArrangementProvider';
import AuthProvider from './context/AuthProvider';
import ClientProvider from './context/ClientProvider';
import CookieProvider from './context/CookieProvider';
import NavProvider from './context/NavProvider';
import PartyProvider from './context/PartyProvider';
import SfsProvider from './context/SfsProvider';
import UtilsProvider from './context/UtilsProvider';
import CustomAccountProvider from './context/CustomAccountProvider';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Common fetcher function for SWR usage
const fetcher = ([url, options]) => fetch(url, options).then((res) => res.json());

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <SWRConfig value={{ fetcher: fetcher }}>
    <UtilsProvider>
      <AuthProvider>
        <CookieProvider>
          <ClientProvider>
            <AccountProvider>
              <PartyProvider>
                <SfsProvider>
                  <ArrangementProvider>
                    <CustomAccountProvider>
                    <NavProvider>
                      <App />
                    </NavProvider>
                    </CustomAccountProvider>
                  </ArrangementProvider>
                </SfsProvider>
              </PartyProvider>
            </AccountProvider>
          </ClientProvider>
        </CookieProvider>
      </AuthProvider>
    </UtilsProvider>
  </SWRConfig>
);