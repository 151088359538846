import { Container, TextField, IconButton } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

import { useAccount } from "../../context/AccountProvider";
import { useArrangement } from "../../context/ArrangementProvider";
import { useSfs } from "../../context/SfsProvider";

/**
 * Amount input
 * @returns
 */
export default function AmountInput({ setErrorMessage }) {

  const { useGetAccount } = useAccount();
  const { account } = useGetAccount();

  const { disposableIncome } = useSfs();
  const { plan, updatePlanEndDateByAmount } = useArrangement();

  const minAmountError = "Your repayment amount must be a minimum of £1";
  const diError = "The amount you want to repay must be affordable for you - please suggest a lower amount";
  const exceedBalanceError = "The amount you want to repay must not exceed your account balance"

  const handleRemoveClick = (event) => {
    setErrorMessage(null);
    let newPaymentAmount = plan?.instalmentAmount - 1;
    if (newPaymentAmount < 1) {
      newPaymentAmount = 1;
      setErrorMessage(minAmountError);
    }
    updatePlanEndDateByAmount(newPaymentAmount);
  };

  const handleAmountChange = (event) => {
    setErrorMessage(null);
    const inputValue = event.target.value.trim();
    // No change if input invalid
    if (inputValue === "" || isNaN(inputValue)) {
      return;
    }
    // No change if input over disposable income
    let newPaymentAmount = parseFloat(inputValue);
    if (newPaymentAmount > disposableIncome) {
      setErrorMessage(diError);
      return;
    }
    if (newPaymentAmount > account?.currentBalance) {
      setErrorMessage(exceedBalanceError);
      return;
    }
    // Do not let amount fall below 1
    if (newPaymentAmount < 1) {
      // Reset the value to 1
      newPaymentAmount = 1;
      setErrorMessage(minAmountError);
    }
    // Update state with the new value
    updatePlanEndDateByAmount(newPaymentAmount);
  };

  const handleAddClick = (event) => {
    setErrorMessage(null);
    let newPaymentAmount = plan?.instalmentAmount + 1;
    if (newPaymentAmount > disposableIncome) {
      setErrorMessage(diError);
      return;
    }
    if (newPaymentAmount > account?.currentBalance) {
      setErrorMessage(exceedBalanceError);
      return;
    }
    updatePlanEndDateByAmount(newPaymentAmount);
  };

  return (
    <Container sx={{ textAlign: "center", padding: 2 }}>
      <IconButton
        id='decrease-btn'
        onClick={handleRemoveClick}
        sx={{
          backgroundColor: "primary.contrastText",
          color: "primary.dark",
          borderRadius: "50%",
          marginTop: 2,
          marginRight: 2,
          "&:hover": {
            backgroundColor: "primary.light",
            color: "primary.dark",
          },
        }}
      >
        <Remove />
      </IconButton>
      <TextField
        onChange={handleAmountChange}
        required
        type='number'
        id='payment-plan-amount'
        name='amount'
        value={plan?.instalmentAmount.toFixed(2)}
        autoFocus
        inputProps={{
          min: 0,
          step: 1,
          style: {
            color: "primary.dark",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 36,
            height: "35px",
          },
        }}
        sx={{
          backgroundColor: "primary.contrastText",
          width: "200px",
          borderRadius: "0px",
        }}
      />
      <IconButton
        id='increase-btn'
        onClick={handleAddClick}
        sx={{
          backgroundColor: "primary.contrastText",
          color: "primary.dark",
          borderRadius: "50%",
          marginTop: 2,
          marginLeft: 2,
          "&:hover": {
            backgroundColor: "primary.light",
            color: "primary.dark",
          },
        }}
      >
        <Add />
      </IconButton>
    </Container>
  );
}
