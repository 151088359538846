import {
	Box,
	Container,
	CircularProgress,
	Typography,
	Button,
	Card,
	CardContent,
} from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import useSWRImmutable from 'swr/immutable'
import environment from '../../env';

import { usePayment } from '../../context/PaymentProvider';


const PaymentConfirmation = ({ amount, accountId, handleComplete, }) => {

	const { paymentId, providerResponse, useGetPayment } = usePayment();
  const { paymentStatus, mutate, error, isLoading } = useGetPayment(paymentId);

	if (error) return (
		<Typography>
			Payment Error
		</Typography>
	)

	if (isLoading) {
		return (
			<Box sx={{
				bgcolor: 'background.paper',
				pt: 8,
				pb: 6,
				textAlign:'center',
			}}>
				<Container maxWidth="sm">
					<CircularProgress color="primary" size="4rem" />
				</Container>
			</Box>
		)
	}

	if (paymentStatus?.status === "PENDING") {
		console.log("Refresh get payment status..");
		mutate();
	}

	if (error) return (
		<Typography>
			Payment Error
		</Typography>
	)

	if (isLoading) {
		return (
			<Box sx={{
				bgcolor: 'background.paper',
				pt: 8,
				pb: 6,
				textAlign:'center',
			}}>
				<Container maxWidth="sm">
					<CircularProgress color="primary" size="4rem" />
				</Container>
			</Box>
		)
	}

	function createData(key, value) {
		return { key, value };
	}
	
	const transformPaymentStatus = (paymentStatus) => {	
		const newPaymentStatus = paymentStatus?.status === "AUTHORISED" || paymentStatus?.status === "PUSHED" || paymentStatus?.status === "COMPLETE" ? {
			success: true,
			icon: <CheckCircle color="success" sx={{ fontSize: 72, mb: 2 }} />,
			title: "Payment Complete",
			subText: `Thank you for your payment of £${amount}`
		} : {
			success: false,
			icon: <Cancel color="error" sx={{ fontSize: 72, mb: 2 }} />,
			title: "Payment Failed",
			subText: "There was a problem processing your payment. Please contact customer support."
		}
		
		return {
			...newPaymentStatus,
			tabularData: [
				createData("Transaction date", new Date().toLocaleDateString()),
				createData("Transaction number", paymentStatus?.paymentId),
				createData("Account reference", accountId)
			]
		}
	}

	const payment = transformPaymentStatus(paymentStatus);

	return paymentStatus ? (
		<Box sx={{
			mx: 'auto',
			p: 3,
			textAlign:'center',
			width: { xs: "100%", md: "65%" },
		}}>
			{payment.icon}
			<Typography
				variant="h5"
				color="text.primary"
				fontWeight="bold"
				paragraph
			>
				{payment.title}
			</Typography>
			<Typography paddingBottom={2}>
				{payment.subText}
			</Typography>

			{payment.tabularData.map((row, idx) => (
				<Card
					key={idx}
					sx={{
						mb: 2,
					}}
				>
					<CardContent>
						<Typography
							textAlign="left"
							width="35%"
							sx={{ display: "inline-block" }}
						>
							{row.key}
						</Typography>
						<Box
							textAlign="right"
							width="65%"
							fontWeight="bold"
							sx={{
								display: "inline-block",
							}}>
							{row.value}
						</Box>
					</CardContent>
				</Card>
			))}
			<Button
				id='continue-btn'
				onClick={handleComplete}
				variant='contained'
				sx={{
					textTransform: "none",
					borderRadius: 0,
					width: { xs: "5em", lg: "10em" },
					backgroundColor: "primary.dark",
					color: "primary.contrastText",
					"&:hover": {
						backgroundColor: "primary.light",
						color: "primary.dark",
					}
				}}
			>
				Return Home
			</Button>
		</Box>
	) : null;

}

export default PaymentConfirmation;