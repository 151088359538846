import { useState, useEffect } from "react";
import { Box, Typography, Stack  } from "@mui/material";

import ChangePaymentMethodSelect from "./ChangePaymentMethodSelect";
import ArrangementCardForm from "./ArrangementCardForm";
import ArrangementDirectDebitForm from "./ArrangementDirectDebitForm";

function ChangePaymentMethod({ account, plan, setPlan, handleComplete }) {

  const [ consent, setConsent ] = useState(false);
  const [ selectedMethod, setSelectedMethod ] = useState(parseInt(plan?.paymentMethod));
  const [ currentSortCode, setCurrentSortCode ] = useState(parseInt(plan?.sortCode));
  const [ currentAccountNumber, setCurrentAccountNumber ] = useState(parseInt(plan?.accountNumber));

  useEffect(() => {
      setPlan({
        ...plan,
        paymentMethod: selectedMethod,
        sortCode: null,
        accountNumber: null
      });
  }, [selectedMethod]);

  const savePaymentMethod = () => {
    setPlan(plan);
    handleComplete();
  };

  return (
    <>
      <Typography fontWeight={"bold"}>
        Change your payment method
      </Typography>
      <Stack
        sx={{
          mt: 2,
        }}
        spacing={2}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "30%" } }}>
          {/* Left column */}
          <ChangePaymentMethodSelect
            account={account}
            consent={consent}
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
            savePaymentMethod={savePaymentMethod}
          />
          {/* End of left column */}
        </Box>
        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
          {/* Right column */}
          {selectedMethod === 11 ?
            <ArrangementDirectDebitForm
              consent={consent}
              setConsent={setConsent}
              update={true}
              currentSortCode={currentSortCode}
              currentAccountNumber={currentAccountNumber}
            /> : 
            <ArrangementCardForm consent={consent} setConsent={setConsent} />}
          {/* End of right column */}
        </Box>
      </Stack>
    </>
  )
}

export default ChangePaymentMethod;