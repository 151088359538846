import { createContext, useContext } from "react";

// Create context object and make available through custom hook
const CookieContext = createContext();
export const useCookie = () => useContext(CookieContext);

const CookieProvider = ({ children }) => {
  // Set a cookie with expiry and path identifier
  const setCookie = (cookieName, cookieValue, numExpiryDays, path) => {
    const date = new Date();
    date.setTime(date.getTime() + numExpiryDays * 24 * 60 * 60 * 1000);
    let expires = `expires=${date.toUTCString()};`;
    document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=${path}`;
  };

  // Find a cookie by name and return name=value
  const getCookie = (cookieName) => {
    let name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookieArray = decodedCookie.split(";");
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return "";
  };

  const contextValue = {
    setCookie,
    getCookie,
  };

  return (
    <CookieContext.Provider value={contextValue}>
      {children}
    </CookieContext.Provider>
  );
};

export default CookieProvider;
