import {
  createContext,
  useContext,
  useState,
  useEffect
} from 'react';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import Pool from '../components/auth/UserPool';
import environment from '../env';
import useSWR, { SWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext)

/**
 * Auth Provider
 * @param {*} param0 
 * @returns 
 */
const AuthProvider = ({ children }) => {

  /**
   * Authentication states
   */
  const authStates = {
    signedOut: "signedOut",
    signedIn: "signedIn",
    signUp: "signUp",
    confirmSignUp: "confirmSignUp",
    forgotPassword: "forgotPassword",
    forgotPasswordSubmit: "forgotPasswordSubmit",
  }

  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken") ?? '')
  const [authState, setAuthState] = useState(authStates.signedOut)
  
  const apiKey = environment.CUSTOMER_PORTAL_API_KEY;
  const apiUrl = environment.CUSTOMER_PORTAL_API;

  const defaultOptions = {
    method: "GET",
    headers: {
      "x-api-key": apiKey,
      "Authorization": `Bearer ${accessToken}`,
    },
  };

  /**
   * Get authenticated user SWR hook
   * @returns 
   */
  function useGetAuthUser() {
    const url = `${apiUrl}/auth/user`;
    console.info("Get authenticated user request: ", {defaultOptions, url})
    const { data, error, isLoading } = useSWRImmutable(accessToken ? [url, defaultOptions] : null, {
      refreshInterval: 60000,
    })
    console.info("Get authenticated user response: ", { data, error, isLoading })
    return {
      user: data,
      error,
      isLoading
    }
  }

  /**
   * Post IDV authentication callback
   * @param {*} url 
   * @param {*} arg 
   * @returns 
   */
  async function postIdvAuth(url, {arg} ) {
    let requestOptions = {
      ...defaultOptions,
      method: 'POST',
      body: JSON.stringify(arg)
    }
    console.info("post idv auth request: ", { url, requestOptions});
    return fetch(`${apiUrl}${url}`, requestOptions).then(res => res.json())
  }

  /**
   * Post sign out callback
   * @param {*} url 
   * @param {*} arg 
   * @returns 
   */
  async function postSignOut(url, {arg} ) {
    let requestOptions = {
      ...defaultOptions,
      method: 'POST',
      body: JSON.stringify(arg)
    }
    console.info("post sign out request: ", { url, requestOptions});
    return fetch(`${apiUrl}${url}`, requestOptions).then(res => res.json())
  }

  /**
   * Post user sign in callback
   * */
  async function postSignIn (username, password) {
    throw new Error('User sign in not yet implemented');
  };

  /**
   * Post user sign up callback
   * */
  async function postSignUp (username, password, userAttributes) {
    throw new Error('User sign upp not yet implemented');
  };

  /**
   * Post confirm user sign up callback
  **/
  async function postConfirmSignUp(confirmationCode) {
    throw new Error('Confirm user sign up not yet implemented');
  };

  /**
   * Post resend confirmation code callback
   * */
  async function postResendConfirmationCode(user) {
    throw new Error('Resend confirmation code not yet implemented');
  };

  /**
   * Post forgot password callback
   * */
  async function postForgotPassword(user){
    throw new Error('Forgot password not yet implemented')
  };

  /**
   * Confirm forgot password callback
   * */
  async function postConfirmForgotPassword(user) {
    throw new Error('Confirm forgot password not yet implemented')
  };

  /**
   * Start session
   * */
  function startSession(authResult) {
    setAccessToken(authResult.accessToken)
    localStorage.setItem('accessToken', authResult.accessToken)
    localStorage.setItem('authResult', JSON.stringify(authResult))
  };

  /**
   * End session
   * */
  function endSession() {
    setAccessToken(null)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('authResult')
  };

  const contextValue = {
    accessToken,
    authStates,
    endSession,
    postConfirmForgotPassword,
    postConfirmSignUp,
    postForgotPassword,
    postResendConfirmationCode,
    postSignIn,
    postSignUp,
    postIdvAuth,
    postSignOut,
    startSession,
    useGetAuthUser,
  }

  return (
    <AuthContext.Provider value={ contextValue }>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;