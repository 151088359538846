import * as React from 'react';
import {
  Box,
  Typography,
  Stack,
  Button,
  Select,
  MenuItem,
  TextField,
  LinearProgress,
  Popper,
  useMediaQuery,
  ListSubheader,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList } from 'react-window';
import { NumericFormat } from "react-number-format";
import AlertMessage from "../AlertMessage";
import { useSfs } from "../../context/SfsProvider";

const LISTBOX_PADDING = 8; // px

const renderRow = (props) => {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = { ...style, top: style.top + LISTBOX_PADDING };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  const { key, ...optionProps } = dataSet;
  return (
    <Typography key={key} component="li" {...optionProps} noWrap style={inlineStyle}>
      {dataSet}
    </Typography>
  );
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    return child.hasOwnProperty('group') ? 48 : itemSize;
  };

  const getHeight = () => {
    return itemCount > 8 ? 8 * itemSize : itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

function SfsAboutYou({ handleNext }) {
  const MAX_PERSON_LIMIT = 20;

  const {
    monthsSinceReview,
    employmentStatus,
    setEmploymentStatus,
    homeStatus,
    setHomeStatus,
    employmentTypeId,
    setEmploymentTypeId,
    employmentTitleId,
    setEmploymentTitleId,
    selectedEmployment,
    setSelectedEmployment,
    partnerEmploymentStatus,
    setPartnerEmploymentStatus,
    household,
    setHousehold,
    useGetOccupationTypes,
    employmentTitleIdMap,
    employmentTypeIdMap,
    useGetOccupationTitles,
    employmentStatuses,
    homeStatuses,
  } = useSfs();

  const { occupationTypesData, isOccupationTypesLoading } = useGetOccupationTypes();
  const { occupationTitlesData, isOccupationTitlesLoading } = useGetOccupationTitles();

  React.useEffect(() => {
    if (employmentTypeId !== null && employmentTitleId !== null) {
      setSelectedEmployment(getOccupationTitleById(employmentTitleId));
    }
  }, [employmentTypeId, employmentTitleId, setSelectedEmployment]);

  if (isOccupationTypesLoading || isOccupationTitlesLoading) {
    return <LinearProgress />;
  }


  const getOccupationTypeLabel = (occupationTypeId) => {
    const occupationTypeObj = occupationTypesData.occupationTypes.find(item => item.occupationTypeId === occupationTypeId);
    return occupationTypeObj?.occupationType || 'Unknown';
  };

  const getOccupationTitleById = (occupationTitleId) => {
    const occupationTitleObj = occupationTitlesData.occupationTitles.find(item => item.occupationTitleId === occupationTitleId);
    console.warn(occupationTitleObj)
    return occupationTitleObj || {"occupationTypeId": employmentTypeIdMap.Unknown, occupationTitleId: employmentTitleIdMap.Uknown, occupationTitle: "Uknown"};
  };

  const handleHouseholdChange = (name, value) => {
    const newHousehold = household.map(item =>
      item.id === name ? { ...item, value } : item
    );
    setHousehold(newHousehold);
  };

  const noSfsMessage = `Hey there! It looks like you haven't completed a Financial Statement with us. 
    Before you can set up a repayment plan we firstly need to make sure that it is affordable for you. 
    Please complete the form below.`;

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      '& ul': {
        padding: 0,
        margin: 0,
      },
    },
  });

  return (
    <>
      {monthsSinceReview === null && <AlertMessage severity="warning" message={noSfsMessage} />}
      <Typography fontWeight="bold" paddingTop={5}>
        Income & Expenditure
      </Typography>
      <Typography paddingBottom={2}>
        Explore financial peace with our user-friendly Income & Expenditure form. Please fill out the information below to start.
      </Typography>
      <Typography paddingBottom={2}>
        You may find that having your recent bills & transaction history to hand may help you complete the form.
      </Typography>

      <Stack sx={{ mt: 2 }} spacing={2} direction={{ xs: "column", md: "row" }}>
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          {/* Left column */}

          <Box id='employment-radio'>
            <Typography
              id='employment-status-label'
              htmlFor='employment-status-select'
              sx={{ color: "#000000", fontWeight: "bold", pb: 3, display: "block" }}
            >
              <strong>Employment status</strong>
            </Typography>
            <Select
              labelId='employment-status-label'
              id='employment-status-select'
              fullWidth
              size='small'
              displayEmpty
              sx={{ borderRadius: "0px", backgroundColor: "#FFF" }}
              value={employmentStatus}
              onChange={(event) => setEmploymentStatus(event.target.value)}
              aria-labelledby='employment-status-label employment-status'
              renderValue={(selected) => selected === "" ? <Typography>Please select</Typography> : employmentStatuses[selected]}
            >
              {Object.keys(employmentStatuses).map((item, idx) => (
                <MenuItem key={idx} value={item}>
                  {employmentStatuses[item]}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box sx={{ width: "100%", pb: 3 }}>
            <Typography
              id='employment-title-label'
              sx={{ color: "#000000", fontWeight: "bold", py: 3, display: "block" }}
            >
              Employment Title
            </Typography>
            <Autocomplete
              value={selectedEmployment}
              onChange={(event, newValue) => {
                if (newValue?.occupationTitleId) {
                  setEmploymentTitleId(newValue.occupationTitleId);
                  setEmploymentTypeId(newValue.occupationTypeId);
                  setSelectedEmployment(newValue);
                } else {
                  setEmploymentTitleId(employmentTitleIdMap.Unknown);
                  setEmploymentTypeId(employmentTypeIdMap.Unknown);
                  setSelectedEmployment({occupationTypeId: employmentTypeIdMap.Unknown,
                                        occupationTitleId: employmentTitleIdMap.Unknown});
                }
              }}
              id="employment-title-select"
              sx={{ width: "100%", backgroundColor: "#FFF" }}
              disableListWrap
              PopperComponent={StyledPopper}
              ListboxComponent={ListboxComponent}
              options={occupationTitlesData.occupationTitles}
              groupBy={(option) => `${getOccupationTypeLabel(option.occupationTypeId).substring(0,60)}...`}
              getOptionLabel={(option) => option.occupationTitle}
              renderInput={(params) => <TextField {...params} label="Please select an employment title" />}
              renderOption={(props, option) => (
                <li {...props} key={option.occupationTitleId}>
                  {option.occupationTitle}
                </li>
              )}
              renderGroup={(params) => <li {...params} />}
            />
          </Box>

          <Box sx={{ pb: 2 }}>
            <Typography
              id='partner-employment-label'
              htmlFor='partner-employment-select'
              sx={{ color: "#000000", fontWeight: "bold", pb: 3, display: "block" }}
            >
              <strong>Partner's Employment status</strong> <i>(optional)</i>
            </Typography>
            <Select
              labelId='partner-employment-label'
              id='partner-employment-select'
              fullWidth
              size='small'
              displayEmpty
              sx={{ borderRadius: "0px", backgroundColor: "#FFF" }}
              value={partnerEmploymentStatus}
              onChange={(event) => setPartnerEmploymentStatus(event.target.value)}
              aria-labelledby='partner-employment-label partner-employment'
              renderValue={(selected) => selected === "" ? <Typography>Please select</Typography> : employmentStatuses[selected]}
            >
              {Object.keys(employmentStatuses).map((item, idx) => (
                <MenuItem key={idx} value={item}>
                  {employmentStatuses[item]}
                </MenuItem>
              ))}
            </Select>
          </Box>

        </Box>

        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          {/* Right column */}

          <Box sx={{ pb: 3 }}>
            <Typography
              id='home-status-label'
              htmlFor='home-status-select'
              sx={{ color: "#000000", fontWeight: "bold", pb: 3, display: "block" }}
            >
              Home Status
            </Typography>
            <Select
              labelId='home-status-label'
              id='home-status-select'
              fullWidth
              size='small'
              displayEmpty
              sx={{ borderRadius: "0px", backgroundColor: "#FFF" }}
              value={homeStatus}
              onChange={(event) => setHomeStatus(event.target.value)}
              aria-labelledby='home-status-label home-status'
              renderValue={(selected) => selected === "" ? <Typography>Please select</Typography> : homeStatuses[selected]}
            >
              {Object.keys(homeStatuses).map((item, idx) => (
                <MenuItem key={idx} value={item}>
                  {homeStatuses[item]}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box>
            <Typography id='household-label' sx={{ fontWeight: "bold", pb: 2 }}>
              Household
            </Typography>

            {household?.map((item, idx) => (
              <Box key={idx} sx={{ display: "flex", pb: 2 }}>
                <Typography
                  id={`${item.id}-label`}
                  htmlFor={`${item.id}`}
                  sx={{ width: "70%", display: "inline", pt: 1 }}
                >
                  {item.label}
                </Typography>

                <NumericFormat
                  id={`${item.id}`}
                  name={`${item.id}`}
                  aria-label={`${item.id} ${item.id}-label`}
                  customInput={(props) => (
                    <TextField
                      {...props}
                      fullWidth
                      size='small'
                      type='number'
                      InputProps={{ style: { borderRadius: 0 } }}
                    />
                  )}
                  value={item.value}
                  displayType='input'
                  allowNegative={false}
                  isAllowed={({ floatValue }) => floatValue <= (item.maxValue || Infinity)}
                  sx={{ backgroundColor: "#FFF" }}
                  onValueChange={({ floatValue }, sourceInfo) => handleHouseholdChange(sourceInfo.event.target.name, floatValue)}
                />
              </Box>
            ))}

          </Box>

        </Box>
      </Stack>

      <Box sx={{ py: 4, textAlign: { xs: "center", md: "right" } }}>
        <Button
          id='next-btn'
          onClick={handleNext}
          variant='contained'
          sx={{
            textTransform: "none",
            borderRadius: 0,
            width: { xs: "10em", lg: "15em" },
            backgroundColor: "primary.dark",
            color: "primary.contrastText",
            "&:hover": {
              backgroundColor: "primary.light",
              color: "primary.dark",
            },
          }}
        >
          Next
        </Button>
      </Box>
    </>
  );
}

export default SfsAboutYou;
