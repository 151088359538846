import React from "react";
import { Box, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";

export default function MaintenancePage() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        borderBottom: "15px solid #005969",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/banner/banner/1/banner.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          margin: "0",
          display: "flex",
          height: 110,
        }}
      >
        <CardMedia
          component='img'
          src={
            "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/logo/intrum_logo/1/intrum_logo.png"
          }
          alt='logo'
          sx={{
            filter: "invert(100%)",
            width: "15%",
            margin: "auto",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
          textAlign: "center",
          padding: "20px",
        }}
      >
        <Typography sx={{ color: "#005969" }} variant='h2'>
          Maintenance Alert!
        </Typography>
        <Typography>
          We're currently performing some essential maintenance on our website.
          Our tech wizards are hard at work behind the scenes to enhance your
          experience.
        </Typography>
        <Typography>
          Please bear with us during this period of time. We promise to be back
          online shortly, better and brighter than ever!
        </Typography>
        <CardMedia
          component='img'
          src={
            "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/spanner_icon/1/spanner_icon.png"
          }
          alt='middle icon'
          sx={{ width: "15%", margin: "20px 0" }}
        />
        <Typography sx={{ fontWeight: "600" }}>
          Thanks for your patience & understanding.
        </Typography>
      </Box>
      <CardMedia
        sx={{
          width: "120px",
          position: "absolute",
          bottom: "15px",
          right: "15px",
        }}
        component='img'
        src={
          "https://cdn-manager.sbx.svc.intrumuk.com/intrum/image/picture/construction_icon/1/construction_icon.png"
        }
        alt='bottom icon'
      />
    </Box>
  );
}
