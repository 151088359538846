import { createContext, useContext, useState } from 'react';

// Create context object and make available through custom hook
const UtilsContext = createContext();
export const useUtils = () => useContext(UtilsContext)

const UtilsProvider = ({ children }) => {

  /**
   * 
   * @param {*} param0 
   * @returns 
   */

  // Alert snackbar severity options
  const alertSeverityTypes = {
    error: "error",
    warning: "warning",
    info: "info",
    success: "success",
  }

  // Alert snackbar state
  const [ alertMessage, setAlertMessage ] = useState('');
  const [ alertSeverity, setAlertSeverity ] = useState(alertSeverityTypes.info);

  function createAlert(message, severity) {
    // Set values to trigger display of alert component
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  // Form control and input change handling
  const [ formState, setFormState ] = useState(null);
  const [ formError, setFormError ] = useState(null);

  // Form input handling
  function onChange(e) {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
    setFormError(null);
  }

  // Class name joining for Tailwind state mutation
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  // Capitalise all words in a string
  const capitaliseWords = (str) => {
    const capitalisedWordsArray = str.toLowerCase().split(" ").map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalisedWordsArray.join(" ");
  };

  const contextValue = {
    alertMessage,
    alertSeverity,
    alertSeverityTypes,
    createAlert,
    formState,
    setFormState,
    onChange,
    formError,
    setFormError,
    classNames,
    capitaliseWords,
  }

  return (
    <UtilsContext.Provider value={ contextValue }>
      {children}
    </UtilsContext.Provider>
  );
}

export default UtilsProvider;