import { Typography } from "@mui/material";
import SfsHelp from "./SfsHelp";

const SfsNegative = () => {
  return (
    <>
      <Typography fontSize={22} fontWeight={"bold"} paddingTop={3}>
        Still need support? Let's get you to the right place
      </Typography>
      <Typography sx={{ pt: 1 }}>
        If you feel that you're struggling with your finances, speaking with
        someone impartial could provide you with options to help you address
        your current balance, learn more about budgeting, understand your credit
        file, and more - all of which are things you maybe weren’t already aware
        of
      </Typography>
      <Typography sx={{ pt: 1 }}>
        It can be tough to talk about money, we understand that - so, for
        independent, free advice (which can be accessed through a range of
        methods, including digital options) - you may want to contact the
        following organisations :
      </Typography>
      <SfsHelp />
    </>
  );
};

export default SfsNegative;
