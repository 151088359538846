import { Container, Box, Typography } from "@mui/material";
import { useArrangement } from "../../context/ArrangementProvider";
import { useAccount } from "../../context/AccountProvider";
import { useUtils } from "../../context/UtilsProvider";

/** 
 * Arrangement confirmation information card component
 * */
const ArrangementConfirmationInfoCard = () => {

  const { useGetAccount } = useAccount();
  const { plan, paymentMethods } = useArrangement();

  const { account } = useGetAccount();
  const { capitaliseWords } = useUtils();

  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "80%", md: "70%" },
        m: { xs: "0", sm: "0 auto", md: "0" },
        borderRadius: 0,
        border: "1px solid lightgrey",
        pb: 3,
      }}
    >
      {/* Header section */}
      <Box sx={{ pl: 2, pb: 1, borderBottom: "1px solid lightgrey", backgroundColor: "#FFF"}}>
        <Typography fontSize={20} fontWeight={"bold"} paddingY={1}>
          {account?.creditorName}
        </Typography>
        <Typography fontSize={13}>Reference number:</Typography>
        <Typography fontSize={16} fontWeight={"bold"}>
          {account?.accountReference}
        </Typography>
      </Box>
      {/* End of header section */}

      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          pt: 1,
          pb: 2,
          px: 3,
        }}
      >
        <Typography
          component='label'
          htmlFor='amount'
          fontSize={13}
          paddingY={0.5}
        >
          Monthly payment amount
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            pb: 3,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >{`£${plan?.instalmentAmount.toFixed(2)}`}</Box>

        <Typography
          component='label'
          htmlFor='amount'
          fontSize={13}
          paddingTop={1}
          paddingBottom={0.5}
        >
          Plan start date
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            py: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >{plan?.dateCreated.format("DD/MM/YYYY")}</Box>

        <Typography
          component='label'
          htmlFor='amount'
          fontSize={13}
          paddingTop={1}
          paddingBottom={0.5}
        >
          Estimated end date
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            py: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >{plan?.endDate.format("DD/MM/YYYY")}</Box>

        <Typography
          component='label'
          htmlFor='amount'
          fontSize={13}
          paddingTop={1}
          paddingBottom={0.5}
        >
          Plan payment method
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E2E2E2",
            height: "34px",
            borderRadius: 0,
            border: "1px solid #BBBBBB",
            px: 1,
            py: 0.5,
            fontWeight: "bold",
            fontSize: 16,
          }}
        >{capitaliseWords(plan?.paymentMethod ? Object.keys(paymentMethods).find(key => paymentMethods[key] === plan.paymentMethod) : "")}</Box>
      </Box>
    </Box>
  );
};

export default ArrangementConfirmationInfoCard;
