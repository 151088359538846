import { useState } from "react";
import { Container, Box, Typography, Stack, Button } from "@mui/material";

import ArrangementDirectDebitForm from "./ArrangementDirectDebitForm";
import ArrangementCardForm from "./ArrangementCardForm";
import ArrangementDetails from "./ArrangementDetails";

import CreateArrangementMethodInfoCard from "./CreateArrangementMethodInfoCard";

import { useArrangement } from "../../context/ArrangementProvider";

 /** 
  * Arrangement method component 
 */
function ArrangementMethod({ handleNext, handleBack }) {

  const { planMode, setHasArrangement, plan, paymentMethods } = useArrangement();
  const [ consent, setConsent ] = useState(false);

  return (
    <>
      <Typography component='label' htmlFor='email' fontWeight={"bold"}>
        {planMode === "create"
          ? "Time to setup your payment method"
          : "Confirm new payment plan"}
      </Typography>
      <Stack
        sx={{
          mt: 2,
        }}
        spacing={2}
        direction={{
          xs: "column",
          md: "row",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
          {/* Left column */}

          {planMode === "create" ? (
            <>
              {/* Plan creation - alternate payment method forms */}
              {plan?.paymentMethod === paymentMethods["UK DIRECT DEBIT"] && <ArrangementDirectDebitForm
                consent={consent}
                setConsent={setConsent}
              />}
              {plan?.paymentMethod === paymentMethods["DEBIT CARD PAYMENT"] && <ArrangementCardForm
                consent={consent}
                setConsent={setConsent}
              />}
            </>
          ) : (
            <>
              {/* Plan amendment - show plan details */}
              <ArrangementDetails setConsent={setConsent} />
            </>
          )}

          {/* End of left column */}
        </Box>

        <Box sx={{ width: { xs: "100%", md: "30%" } }}>
          {/* Right column */}
          {planMode === "create" && <CreateArrangementMethodInfoCard />}

          {/* Call To Action */}
          <Box sx={{ textAlign: "center", pt: 5, pb: 4 }}>
            <Container sx={{ pb: 2 }}>
              <Button
                onClick={() => {
                  setHasArrangement(true);
                  handleNext();
                }}
                variant='contained'
                disabled={!consent}
                sx={{
                  textTransform: "none",
                  borderRadius: 0,
                  width: { xs: "15em", lg: "20em" },
                  backgroundColor: "primary.dark",
                  color: "primary.contrastText",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    color: "primary.dark",
                  },
                }}
              >
                Continue
              </Button>
            </Container>

            <Container sx={{ pb: 2 }}>
              <Button
                id='back-btn'
                onClick={handleBack}
                variant='outlined'
                sx={{
                  textTransform: "none",
                  borderRadius: 0,
                  border: "1px solid",
                  width: { xs: "15em", lg: "20em" },
                  backgroundColor: "#FFF",
                  color: "secondary.main",
                  "&:hover": {
                    backgroundColor: "secondary.main",
                    color: "#FFF",
                    border: "1px solid",
                  },
                }}
              >
                Back
              </Button>
            </Container>
          </Box>

          {/* End of right column */}
        </Box>
      </Stack>
    </>
  );
}

export default ArrangementMethod;
