import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import PaymentAmount from "./PaymentAmount";
import PaymentFrame from "./PaymentFrame";
import PaymentConfirmation from "./PaymentConfirmation";

import { usePayment } from "../../context/PaymentProvider";
import { useAuth } from "../../context/AuthProvider";
import { useAccount } from "../../context/AccountProvider";

/**
 * Payment Stepper component
 * @returns  
 */
export default function PaymentStepper() {
  const { useGetAccount } = useAccount();
  const { account } = useGetAccount()

  const {
    activeStep,
    setActiveStep,
    paymentSteps,
    paymentAmount,
    updatePaymentAmount,
  } = usePayment();

  const navigate = useNavigate();

  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (isComplete) {
      navigate('/');
      window.location.reload();
    }
  }, [isComplete, navigate]);

  /**
   * Handle stepper next button
   */
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  /**
   * Handle stepper back button
   */
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /**
   * Handle complete button
   */
  const handleComplete = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setIsComplete(true);  // Trigger the completion state
  };

  return account && (
    <Box sx={{ width: "100%", marginTop: 2, marginBottom: 35 }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          my: 3,
          "& .MuiStepConnector-root": {
            top: "21px",
            left: "calc(-50% + 35px)",
            right: "calc(50% + 35px)",
          },
          "& .MuiStepConnector-root-line": {
            right: "35px",
            left: "35px",
            flex: "1 1 auto",
            position: "absolute",
            top: "21px",
          },
        }}
      >
        {paymentSteps.map((step) => (
          <Step id={step.id} key={step.title}>
            <StepLabel
              StepIconProps={{
                sx: {
                  fontSize: "45px",
                  "&.Mui-active": {
                    color: "primary.dark",
                  },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
              sx={{
                "& .MuiStepLabel-labelContainer": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
                "& .MuiStepLabel-root": {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              {step.title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ textAlign: 'center' }}>
        <>
          {activeStep === 0 && (
            <PaymentAmount
              handleNext={handleNext}
              balance={account.currentBalance || 0}
              paymentAmount={paymentAmount}
              updatePaymentAmount={updatePaymentAmount}
            />
          )}

          {activeStep === 1 && (
            <PaymentFrame
              handleNext={handleNext}
              amount={paymentAmount}
              accountId={account?.accountId?.toString()}
            />
          )}

          {activeStep === 2 && (
            <PaymentConfirmation
              amount={paymentAmount}
              handleComplete={handleComplete}
              accountId={account?.accountReference?.toString()}
            />
          )}
        </>
      </Box>
    </Box>
  );
}
